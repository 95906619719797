import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {initializeApp, FirebaseApp } from "firebase/app";
import {getAnalytics } from "firebase/analytics";
import {getStorage, connectStorageEmulator} from 'firebase/storage';


const isLocal=true

const testFirebase = (app: FirebaseApp): String => {
try {
  const features = [
    // getAuth(app),
    // getDatabase(app),
    // getFirestore(app),
    // getFunctions(app),
    // getMessaging(app),
    [getStorage(app), "storage"],
    [getAnalytics(app), "analytics"],
    // getRemoteConfig(app),
    // getPerformance(app),
  ].filter(feature => feature[0] !== null)
  .map(tup => { return tup[1] })
  
  return `Firebase SDK loaded with (${features.join(', ')})`;
} catch (e) {
  console.error(e);
  return 'Error loading the Firebase SDK, check the console.';
};
}

if (isLocal) {
  const firebaseConfig = {
    apiKey: "AIzaSyDHjiNB1vfxFGSLGPBEhatjJzDQD3Cnh-M",
    authDomain: "titorial-e5a7d.firebaseapp.com",
    projectId: "titorial-e5a7d",
    storageBucket: "titorial-e5a7d.appspot.com",
    messagingSenderId: "765126118366",
    appId: "1:765126118366:web:ec9be228fd11c304106b4f",
    measurementId: "G-CRZY97F3Q0",
    databaseURL: 'http://localhost:9000/?ns=YOUR_PROJECT_ID'
  };
  const app = initializeApp(firebaseConfig);
  connectStorageEmulator(getStorage(), 'localhost', 9199);
  console.log(testFirebase(app))
} else {
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDHjiNB1vfxFGSLGPBEhatjJzDQD3Cnh-M",
  authDomain: "titorial-e5a7d.firebaseapp.com",
  projectId: "titorial-e5a7d",
  storageBucket: "titorial-e5a7d.appspot.com",
  messagingSenderId: "765126118366",
  appId: "1:765126118366:web:ec9be228fd11c304106b4f",
  measurementId: "G-CRZY97F3Q0"
};
const app = initializeApp(firebaseConfig);
const analytics =  getAnalytics(app)
console.log(testFirebase(app))
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


